import React from 'react'
import { Link } from 'react-router-dom'
import './PageDrinks.css'

export default function PageDrinks() {
  return (
    <div className={`PageDrinks`}>
      {/* <img src="/assets/images/drinks.jpg" width="100%" alt="Drinks" /> */}
      <ul className={`PageDrinks-list`}>
        <li><Link to="/drinks/longdrinks">Longdrinks</Link></li>
        <li><Link to="/drinks/cocktails">Cocktails</Link></li>
        <li><Link to="/drinks/cocktails-alkoholfrei">Cocktails alkoholfrei</Link></li>
        <li><Link to="/drinks/softdrinks">Softdrinks</Link></li>
        <li><Link to="/drinks/warme-getraenke">Warme Getränke</Link></li>
        <li><Link to="/drinks/weine-und-sekt">Weine & Sekt</Link></li>
        <li><Link to="/drinks/biere">Biere</Link></li>
        <li><Link to="/drinks/flaschen">Flaschen</Link></li>
        <li><Link to="/drinks/milchshakes">Milchshakes</Link></li>
      </ul>
    </div>
  )
}
