import React from 'react'

export default function Item() {
  const meta = {
    "allergene": [
      { "title": "Weizen", "category": "Glutenhaltiges Getreide", "id": "A" },
      { "title": "Gerste", "category": "Glutenhaltiges Getreide", "id": "B" },
      { "title": "Roggen", "category": "Glutenhaltiges Getreide", "id": "C" },
      { "title": "Hafer", "category": "Glutenhaltiges Getreide", "id": "D" },
      { "title": "Dinkel", "category": "Glutenhaltiges Getreide", "id": "E" },
      { "title": "Milch", "category": "Milch und Milchprodukte", "id": "F" },
      { "title": "Laktose", "category": "Milch und Milchprodukte", "id": "G" },
      { "title": "Ei", "category": "Eier", "id": "H" },
      { "title": "Erdnüsse", "category": "Erdnüsse", "id": "I" },
      { "title": "Sojabohnen", "category": "Sojabohnen", "id": "J" },
      { "title": "Mandeln", "category": "Schalenfrüchte", "id": "K" },
      { "title": "Haselnüsse", "category": "Schalenfrüchte", "id": "L" },
      { "title": "Walnüsse", "category": "Schalenfrüchte", "id": "M" },
      { "title": "Cashewnüsse", "category": "Schalenfrüchte", "id": "N" },
      { "title": "Fisch", "category": "Fisch", "id": "O" },
      { "title": "Krabben", "category": "Krebstiere", "id": "P" },
      { "title": "Garnelen", "category": "Krebstiere", "id": "Q" },
      { "title": "Hummer", "category": "Krebstiere", "id": "R" },
      { "title": "Austern", "category": "Weichtiere", "id": "S" },
      { "title": "Muscheln", "category": "Weichtiere", "id": "T" },
      { "title": "Tintenfisch", "category": "Weichtiere", "id": "U" }
    ],
    "zusatzstoffe": [
      { "title": "Sorbinsäure", "category": "Konservierungsstoffe", "id": 1 },
      { "title": "Natriumbenzoat", "category": "Konservierungsstoffe", "id": 2 },
      { "title": "Tartrazin", "category": "Farbstoffe", "id": 3 },
      { "title": "Chinolingelb", "category": "Farbstoffe", "id": 4 },
      { "title": "Ascorbinsäure", "category": "Antioxidationsmittel", "id": 5 },
      { "title": "Tocopherole", "category": "Antioxidationsmittel", "id": 6 },
      { "title": "Mononatriumglutamat", "category": "Geschmacksverstärker", "id": 7 },
      { "title": "Lecithine", "category": "Emulgatoren", "id": 8 },
      { "title": "Mono- und Diglyceride von Speisefettsäuren", "category": "Emulgatoren", "id": 9 },
      { "title": "Xanthan", "category": "Verdickungsmittel", "id": 10 },
      { "title": "Guarkernmehl", "category": "Verdickungsmittel", "id": 11 },
      { "title": "Saccharin", "category": "Süßungsmittel", "id": 12 },
      { "title": "Aspartam", "category": "Süßungsmittel", "id": 13 },
      { "title": "Citronensäure", "category": "Säuerungsmittel", "id": 14 },
      { "title": "Äpfelsäure", "category": "Säuerungsmittel", "id": 15 },
      { "title": "natürliche Aromastoffe", "category": "Aromen", "id": 16 },
      { "title": "künstliche Aromastoffe", "category": "Aromen", "id": 17 }
    ]
  }

  return (
    <>
      <div class="spacer" />
      <div class="Item">
        <div class="Item-head">
          <h3 class="Item-title">Allergene & Zusatzstoffe</h3>
        </div>
        <p class="Item-description">
          {Object.keys(meta.allergene).map(key => `${meta.allergene[key].id}) ${meta.allergene[key].title}`).join(', ')}
          {/* 1) mit Farbstoffen, 2) mit Konservierungsstoffen, 3) mit Antioxidationsmittel, 4) mit Geschmacksverstärker(n), 5) Schwefeldioxid, 6) mit Schwärzungsmittel, 7) mit Phosphat, 8) mit Milcheiweiß, 9) koffeinhaltig, 10) chininhaltig, 11) gwachst, 12) mit Taurin, 13) enthält eine Phenylalaninquelle, 14) mit Süßungsmittel, 15) mit Säuerungsmittel */}
        </p>
        <br />
        <p class="Item-description">
          {Object.keys(meta.zusatzstoffe).map(key => `${meta.zusatzstoffe[key].id}) ${meta.zusatzstoffe[key].title}`).join(', ')}
          {/* 1) mit Farbstoffen, 2) mit Konservierungsstoffen, 3) mit Antioxidationsmittel, 4) mit Geschmacksverstärker(n), 5) Schwefeldioxid, 6) mit Schwärzungsmittel, 7) mit Phosphat, 8) mit Milcheiweiß, 9) koffeinhaltig, 10) chininhaltig, 11) gwachst, 12) mit Taurin, 13) enthält eine Phenylalaninquelle, 14) mit Süßungsmittel, 15) mit Säuerungsmittel */}
        </p>
      </div>
    </>
  )
}