import React, { useRef } from 'react'

export default function ExternalLink({children, to, className}) {
  const el = useRef()
  const handleClick = (event) => {
    if (window.ipcRenderer) {
      event.preventDefault();
      window.ipcRenderer.send('open-external-link', el.current.href);
    }
  }
  return (
    <a ref={el} className={className} href={to} target="_blank" onClick={handleClick}>
      {children}
    </a>
  )
}
