import { useEffect, useState } from 'react';
import { on, off, trigger } from './CustomEvents';
import axios from 'axios';

export default function useUser() {
  const [loggedIn, setloggedIn] = useState(false) // make later inital check if valid token exists in storage
  const [data, setdata] = useState(null) // make later inital check if valid token exists in storage

  window.user = {
    loggedId: loggedIn,
    setloggedIn: setloggedIn,
    data: data
  }

  const onLogin = (event) => {
    axios.post('http://localhost:8080/auth/login', {
      email: event.detail.email,
      password: event.detail.password
    })
    .then((response) => {
      console.log(response);
      setdata(response.data);
      setloggedIn(true);
      trigger('user:login/success', response.data);
    })
    .catch((error) => {
      console.log(error);
      setloggedIn(true);
      trigger('user:login/error', error.response.data);
    });
  }

  const onLogout = (event) => {
    console.log('#LOGIN LOGOUT #DATA', event.detail);
    setloggedIn(false);
  }


  useEffect(
    () => {
      // Set up
      on("user:login", onLogin)
      on("user:logout", onLogout)

      // Clean up
      return () => {
        off("user:login", onLogin)
        off("user:logout", onLogout)
      };
    },
    []
  );
}