import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, combineReducers } from 'redux';
import { Provider } from 'react-redux'
import rootReducer from './stores/rootReducer';
import loginReducer from './stores/loginReducer';
import store from './store/store';

import './styles/fonts.css';
import './styles/index.css';

import App from './App';
// import reportWebVitals from './reportWebVitals';


const store_old = createStore(
  combineReducers({
    root: rootReducer,
    login: loginReducer
  })
);

window.store = store_old;
window.stores = {
  root: store_old.getState().root,
  login: store_old.getState().login,
}

window._store = store;

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.body // document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
