import React from 'react'
import './FormMessage.css'

export default function FormMessage({response}) {
  return (
    <>
      {
        response.message ?
        <p className="FormMessage">{response.message}</p>
        : null
      }
    </>
  )
}
