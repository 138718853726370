import React from 'react'
import {
  Link
} from "react-router-dom"
import './Button.css'

const Button = ({ children, onClick }) => {
  return (
    <button className="Button" onClick={onClick}>{children}</button>
  )
}

const Muted = ({children, to}) => {
  return (
    <>
      {
        to ?
        <Link to={to} className="Button-Muted">{children}</Link>
        :
        <button className="Button-Muted">{children}</button>
      }
    </>
  )
}

const Icon = ({children, onClick}) => {
  return (
    <button className="Button-Icon" onClick={onClick}>{children}</button>
  )
}

const Primary = ({children, onClick, rounded, disabled}) => {
  return (
    <button className={`Button-Primary${ rounded ? ' Button-rounded' : '' }`} onClick={onClick} disabled={disabled}>{children}</button>
  )
}

const ButtonLink = ({children, onClick}) => {
  return (
    <button className={`Button-Link`} onClick={onClick}>{children}</button>
  )
}

const Secondary = ({children, onClick, rounded, disabled}) => {
  return (
    <button className={`Button-Secondary${ rounded ? ' Button-rounded' : '' }`} onClick={onClick} disabled={disabled}>{children}</button>
  )
}

const Download = ({children, to}) => {
  return (
    <>
      {
        to ?
        <Link to={to} className="Button-Download">{children}</Link>
        :
        <button className="Button-Download">{children}</button>
      }
    </>
  )
}

Button.Muted = Muted;
Button.Icon = Icon;
Button.Download = Download;
Button.Primary = Primary;
Button.Secondary = Secondary;
Button.Link = ButtonLink;

export default Button;

