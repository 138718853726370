import React, { useState, useRef, useEffect } from 'react'
import './Sheet.css'
import IconClose from '../../icons/IconClose'
import { off, on, trigger } from '../../utils/CustomEvents'


export default function Sheet({children, open, setopen, title, footer, name}) {
  const [minimal, setminimal] = useState(false)
  const [didHit, setdidHit] = useState(false)
  const [didMove, setdidMove] = useState(false)
  const [eventPrev, seteventPrev] = useState()
  const [eventStart, seteventStart] = useState()
  const [scrollTop, setscrollTop] = useState(0)
  const [disablePointerEvent, setdisablePointerEvent] = useState(false)

  const ref = useRef()

  let dX, dY, dXInverted, dYInverted, openFactor;
  openFactor = 0;

  if (eventPrev && eventStart) {
    dX = eventPrev.x - eventStart.x;
    dY = eventPrev.y - eventStart.y;
    
    dXInverted = eventStart.x - eventPrev.x;
    dYInverted = eventStart.y - eventPrev.y;
  }

  let translateString = '';
  let translateStringFooter = '';

  useEffect(() => {
    const onMinimalOn = (event) => {
      setminimal(true);
    }
    const onMinimalOff = (event) => {
      setminimal(false);
    }
    on("sheet:minimal/on", onMinimalOn)
    on("sheet:minimal/off", onMinimalOff)
    return () => {
      off("sheet:minimal/on", onMinimalOn)
      off("sheet:minimal/off", onMinimalOff)
    },
    [setminimal]
  });

  useEffect(() => {


    const handleDragStart = (event) => {
      console.log('test open', open);
      if (open && window.currentSheet == name) {
        document.getElementsByTagName( 'html' )[0].classList.add('Sheet-hit');
        console.log('drag start', event);
        seteventStart(event);
        setdidHit(true);
      }
    }
    const handleDragMove = (event) => {
      if (didHit) {
        console.log('drag move', event);
        seteventPrev(event);
      

        if (dY > 20 && open && scrollTop <= 0) {
          setdisablePointerEvent(false);
        }
      }
    }
    const handleDragEnd = (event) => {
      if (didHit) {

        console.log('drag end', event);
        setTimeout(() => {
          
          setdidHit(false);
          seteventPrev(undefined);
        },1);
        if (dY > 80 && open) {
          setopen(false);
        }
        document.getElementsByTagName( 'html' )[0].classList.remove('Sheet-hit');
      }
    }

    window.addEventListener('pointerdown', handleDragStart);
    window.addEventListener('pointermove', handleDragMove);
    window.addEventListener('pointerup', handleDragEnd);
    window.addEventListener('pointercancel', handleDragEnd);

    return () => {
      window.removeEventListener('pointerdown', handleDragStart);
      window.removeEventListener('pointermove', handleDragMove);
      window.removeEventListener('pointerup', handleDragEnd);
      window.removeEventListener('pointercancel', handleDragEnd);  
    };
  }, [setopen, open, didHit, setdidHit, eventPrev, seteventPrev, eventStart, seteventStart, setdisablePointerEvent])

  const onScroll = (event) => {

    if (event.target.scrollTop < 0 && didHit) {
      event.preventDefault();
      setdisablePointerEvent(true);
    }
    setscrollTop(ref.current.scrollTop);

    if (ref.current.scrollTop < - 120) {
      setopen(false);
    }
  }


  let keyboardViewportOffset = window.innerHeight - visualViewport.height;
  let offsetBottom = ''
  if (open) {
    let sab = ' - env(safe-area-inset-bottom, 0px)';
    if (keyboardViewportOffset > 0 || minimal) {
      keyboardViewportOffset = ' 100% - 45px + ';
      offsetBottom = ' - 45px'
      sab = '';
    } else {
      keyboardViewportOffset = ''
      offsetBottom = ' - 45px - 72px'
    }

    // open
      translateString = `translate3d(0,calc(${0}px${sab}${offsetBottom}),0)`;
      translateStringFooter = `translate3d(0,calc(${keyboardViewportOffset}${0}px${sab}),0)`;

    if (true && (dY > 20 || dY < -20)) { 
      // translate
      translateString = `translate3d(0,calc(${(dY-20)}px${sab}${offsetBottom}),0)`;
      translateStringFooter = `translate3d(0,calc(${keyboardViewportOffset}${(dY-20) * 0.2}px${sab}),0)`;
    }
    
    if (true && dY < -20) { 
      // translate up overscroll
      translateString = `translate3d(0,calc(${(dY+20) * 0.3}px${sab}${offsetBottom}),0)`;
      translateStringFooter = `translate3d(0,calc(${keyboardViewportOffset}${(dY+20) * 0.15}px${sab}),0)`;
    }

  } else {
    // closed
    translateString = `translate3d(0,${window.innerHeight}px,0)`;
    translateStringFooter = `translate3d(0,100%,0)`;
  }


  window.sheets[name] = {
    open: open,
    setopen: setopen
  }

  if (open) {
    document.getElementsByTagName( 'html' )[0].classList.add('Sheet-open');
  } else {
    document.getElementsByTagName( 'html' )[0].classList.remove('Sheet-open');
  }

  return (
    <>
      <div className="Sheet-blend" style={{
        opacity: open ? 0.8 : 0,
        pointerEvents: open ? 'inherit' : 'none'
      }} onClick={()=>{
        if (!dY || (dY > -20 && dY < 20)) {
          setopen(false)
        }
      }}></div>

      <div className={`Sheet`} style={{
        transform: translateString,
        bottom: window.innerHeight - visualViewport.height,
        maxHeight: (keyboardViewportOffset > 0 || minimal) ? visualViewport.height - 45 : 'calc( 100% - (46px + 72px + 58px))'
        // pointerEvents: !disablePointerEvent ? 'inherit' : 'none'
      }}>
        <div className={`Sheet-head`}>
          <span className="Sheet-title" style={{
            opacity: open ? 1 : 0
          }}>{title}</span>
          <button onClick={()=>{setopen(false)}} className="Sheet-close">
            <IconClose />
          </button>
        </div>
        <div className={`Sheet-body`} ref={ref} onScroll={onScroll}>
          {children}
          <h1>scroll</h1>
          <h1>scroll</h1>
          <h1>scroll</h1>
          <h1>scroll</h1>
          <h1>scroll</h1>
          <h1>scroll</h1>
          <h1>scroll</h1>
          <h1>scroll</h1>
          <h1>scroll</h1>
          <h1>scroll</h1>
          <h1>scroll</h1>
          <h1>scroll</h1>
        </div>
      </div>
      <div className={`Sheet-footer`} style={{
        transform: translateStringFooter,
        bottom: window.innerHeight - visualViewport.height
      }}>{footer}</div>



      <div className={`Sheet-log`}>
        {
          !eventStart ? 'no event' :
          <>
            X: {eventStart.x} <br/>
            Y: {eventStart.y} <br/>
            <br/>
            {
              !eventPrev ? <>dX: <br/>dY: <br/></> :
              <>
                dX: { eventPrev.x - eventStart.x } <br/>
                dY: { eventPrev.y - eventStart.y } <br/>
              </>
            }
            <br/>
            W: {window.innerWidth} <br/>
            H: {window.innerHeight} <br/>
            dX: {window.innerHeight} <br/>
            dY: {window.innerHeight} <br/>
            open: { open ? 'true' : 'false' } <br/>
            open factor: { dX / (window.innerWidth/4) } <br/>
            scrollTop: {scrollTop} <br/>
            scrollWidth: {ref.current.scrollWidth} <br/>
            scrollOffsetRight: {ref.current.scrollWidth - ref.current.clientWidth} <br/>
            disablePointerEvent: {disablePointerEvent ? 'true' : 'false' }


          </>
        }
      </div>
    </>
  )
}
