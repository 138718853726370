import React, { useState, useRef } from 'react'
import './Tabbar.css'
import {
  NavLink
} from "react-router-dom";
import IconOverview from '../../icons/IconOverview';
import IconDrinks from '../../icons/IconDrinks';
import IconFavorites from '../../icons/IconFavorites';
import IconShishas from '../../icons/IconShishas';
import IconSnacks from '../../icons/IconSnacks';
import IconSearch from '../../icons/IconSearch';
import IconClose from '../../icons/IconClose';

export default function Tabbar({hide, env}) {
  const [keyboardOpen, setkeyboardOpen] = useState(false);
  const [searchFocused, setsearchFocused] = useState(false);
  const [searchString, setsearchString] = useState('');
  let prettyBottom = Math.round(parseFloat(env.bottom.replace('px', ''))*100)/100 * -1;
  let hideNot = false;
  let hasTop = true;

  if (prettyBottom == 0) {
    hideNot = true;
  }
  
  
  if (hide && !hideNot) {
    if (hasTop && !keyboardOpen) {
      prettyBottom = 72 + prettyBottom;
    } else {
      prettyBottom = 72;
    }
  }

  if (keyboardOpen) {
    prettyBottom = 72;
  }


  const styles = {
    Tabbar: {
      transform: `translate3d(0,${ prettyBottom }px,0)`
    }
  }

  const onFocus = () => {
    setkeyboardOpen(true)
    setsearchFocused(true)
  }
  const onBlur = () => {
    setkeyboardOpen(false)
    setsearchFocused(false)
  }
  const onInput = (event) => {
    setsearchString(event.target.value);
  }

  const refSearch = useRef();
  const searchHasValue = () => {
    return (
      searchString &&
      searchString.trim() != ''
    )
  }

  const clearSearch = (event) => {
    event.preventDefault();
    if (searchHasValue()) {
      refSearch.current.focus();
    }
    refSearch.current.value = '';
    setsearchString('');
  }


  return (
    <div className={`Tabbar${ hide && !hideNot ? ' Tabbar-hidden' : '' }`} style={styles.Tabbar}>
      {/* <div className={`Tabbar-top`}>
        <div className={`Tabbar-Search${ searchFocused ? ' Tabbar-Search-focused' : '' }${ searchHasValue() ? ' Tabbar-Search-hasValue' : '' }`}>
          <input ref={refSearch} type="text" onFocus={onFocus} onBlur={onBlur} onInput={onInput} />
          <div className={`Tabbar-Search-icon`}>
            <IconSearch />
          </div>
          <span className={`Tabbar-Search-placeholder`}>Suchen</span>
          <button className={`Tabbar-Search-close`} onClick={clearSearch}>
            <IconClose />
          </button>
        </div>
      </div> */}
      <div className={`Tabbar-inner`}>
        {/* Tabbar {JSON.stringify(env)} /// ${env.bottom} /// {prettyBottom} */}

        <nav className={`Tabbar-nav`}>
          <NavLink className="Tabbar-tab"  activeClassName='is-active' to={'/'} exact={true}>
            <div className="Tabbar-icon-wrapper">
              <IconShishas />
            </div>
            <span className="Tabbar-tab-title">Shishas</span>
          </NavLink>
          <NavLink className="Tabbar-tab"  activeClassName='is-active' to={'/drinks'} exact={false}>
            <div className="Tabbar-icon-wrapper">
              <IconDrinks />
            </div>
            <span className="Tabbar-tab-title">Drinks</span>
          </NavLink>
          <NavLink className="Tabbar-tab"  activeClassName='is-active' to={'/food'} exact={false}>
            <div className="Tabbar-icon-wrapper">
              <IconSnacks />
            </div>
            <span className="Tabbar-tab-title">Food</span>
          </NavLink>
          <NavLink className="Tabbar-tab"  activeClassName='is-active' to={'/infos'} exact={false}>
            <div className="Tabbar-icon-wrapper">
              <IconOverview />
            </div>
            <span className="Tabbar-tab-title">Infos</span>
          </NavLink>
          {/* <NavLink className="Tabbar-tab"  activeClassName='is-active' to={'/favoriten'} exact={false}>
            <div className="Tabbar-icon-wrapper">
              <IconFavorites />
            </div>
            <span className="Tabbar-tab-title">Favoriten</span>
          </NavLink> */}
        </nav>

      </div>
    </div>
  )
}
