import React, { useEffect, useState } from 'react'
import CardSnack from '../../components/CardSnack/CardSnack';
import Item from '../../components/Item/Item';
import Additives from '../../components/Additives';

export default function PageSnacks() {

  const [data, setdata] = useState([]);
  const [dataToast, setdataToast] = useState([]);
  const [dataBaguette, setdataBaguette] = useState([]);
  
  const getData = ()=>{
    fetch('/data/snacks.json?v=1.0.7', {
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }).then(function(response){
      return response.json();
    }).then(function(json) {
      setdata(json);
    });

    fetch('/data/snacks/baguette.json?v=1.0.7', {
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }).then(function(response){
      return response.json();
    }).then(function(json) {
      setdataBaguette(json);
    });

    fetch('/data/snacks/toast.json?v=1.0.7', {
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }).then(function(response){
      return response.json();
    }).then(function(json) {
      setdataToast(json);
    });
  }
  
  useEffect(() => {
    getData();
  }, []);

  return (
    <>

      <h2 class="px">Baguette</h2>
      <p class="px">Alle Baguettes mit Remoulade & Gouda- oder Schmelzkäse.<br />Auf Wunsch mit Tomaten, sauren Gurken, Röstzwiebeln, Jalapenos oder scharfer Soße.</p>
      {dataBaguette.map((item, index) => (
        <Item key={index} data={item} />
      ))}

      <h2 class="px">Toast</h2>
      <p class="px">Alle Toasts mit Remoulade & Gouda- oder Schmelzkäse.<br />Auf Wunsch mit Tomaten, sauren Gurken, Röstzwiebeln, Jalapenos oder scharfer Soße.</p>
      {dataToast.map((item, index) => (
        <Item key={index} data={item} />
      ))}

      <h2 class="px">Extras</h2>
      {data.map((item, index) => (
        <Item key={index} data={item} />
      ))}

      <Additives />
    </>
  )
}
