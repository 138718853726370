import React from 'react'
import './Header.scss';
import {
  Switch,
  Route,
  useLocation
} from "react-router-dom";
import IconScan from '../../icons/IconScan';

export default function Header({hide, pullFactor, slotLeft}) {
  let location = useLocation();

  return (
    <div className={`Header${hide ? ' Header-hidden' : ''}${pullFactor > 1 ? ' Header-hidden-pull' : ''}`}>
      {
        !slotLeft ? null :
        <div className="Header-slot-left">
          {slotLeft}
        </div>
      }
      <Switch location={location}>
        {/* <Route path="/registrieren/:id">
          <div className="Header-title">Registrierung abschließen</div>
        </Route>
        <Route path="/registrieren">
          <div className="Header-title">Registrieren</div>
        </Route>
        <Route path="/anmelden">
          <div className="Header-title">Anmelden</div>
        </Route> */}

        <Route path="/impressum">
          <div className="Header-title">Impressum</div>
        </Route>

        <Route path="/datenschutz">
          <div className="Header-title">Datenschutzerklärung</div>
        </Route>


        <Route path="/infos">
          <div className="Header-title">Shishas</div>
        </Route>


        <Route path="/drinks/longdrinks">
          <div className="Header-title">Longdrinks</div>
        </Route>
        <Route path="/drinks/cocktails">
          <div className="Header-title">Cocktails</div>
        </Route>
        <Route path="/drinks/cocktails-alkoholfrei">
          <div className="Header-title">Cocktails alkoholfrei</div>
        </Route>
        <Route path="/drinks/softdrinks">
          <div className="Header-title">Softdrinks</div>
        </Route>
        <Route path="/drinks/warme-getraenke">
          <div className="Header-title">Warme Getränke</div>
        </Route>
        <Route path="/drinks/weine-und-sekt">
          <div className="Header-title">Weine & Sekt</div>
        </Route>
        <Route path="/drinks/biere">
          <div className="Header-title">Biere</div>
        </Route>
        <Route path="/drinks/flaschen">
          <div className="Header-title">Flaschen</div>
        </Route>
        <Route path="/drinks/milchshakes">
          <div className="Header-title">Milchshakes</div>
        </Route>

        <Route path="/drinks/:group">
          <div className="Header-title">Drinks group</div>
        </Route>
        <Route path="/drinks">
          <div className="Header-title">Drinks</div>
        </Route>
        <Route path="/food">
          <div className="Header-title">Food</div>
        </Route>
        {/* <Route path="/favoriten">
          <div className="Header-title">Favoriten</div>
        </Route> */}


        <Route exact path="/">
          <div className="Header-title">Long Island</div>
        </Route>
        <Route path="*">
          <div className="Header-title">404</div>
        </Route>
      </Switch>
    </div>
  )
}
