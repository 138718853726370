import React, { useEffect, useState } from 'react'
import Item from '../../components/Item/Item';
import useBodyClass from '../../utils/useBodyClass';

export default function PageShisha({data}) {
  const [_data, set_data] = useState([]);
  
  const getData = ()=>{
    fetch('/data/shisha.json?v=1.0.7', {
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }).then(function(response){
      return response.json();
    }).then(function(json) {
      set_data(json);
    });
  }
  
  useEffect(() => {
    getData();
  }, []);

  const open = () => {
    console.log('slide up open', data);
    data.setslideUpOpen(true);
  }

  useBodyClass(`Page-Shishas`);

  return (
    <>
      <img src="/images/shisha-hero.jpg" width="100%" alt="Shisha" className="no-drag" />
      <div>
        {_data.map((item, index) => (
          <>
            {
              item.heading ? 
              <h2 class="px">{item.title}</h2> : 
              <Item key={index} data={item} />
            }
          </>
        ))}
        
      </div>
    </>
  )
}
