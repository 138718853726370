import React from 'react'
import './Item.scss'

export default function Item({data, subData}) {
  const isHappyHour = () => {
    return data.happy_hour ? 'Happy hour' : null;
  }

  let quantity = '';

  if (typeof data.quantity == 'object') {
    data.quantity.forEach((q, index) => {
      if (index > 0) {
        quantity += ' - '
      }
      quantity += q[0]
    });
  } else {
    quantity = data.quantity;
  }

  const meta = {
    "allergene": [
      { "title": "Weizen", "category": "Glutenhaltiges Getreide", "id": "A" },
      { "title": "Gerste", "category": "Glutenhaltiges Getreide", "id": "B" },
      { "title": "Roggen", "category": "Glutenhaltiges Getreide", "id": "C" },
      { "title": "Hafer", "category": "Glutenhaltiges Getreide", "id": "D" },
      { "title": "Dinkel", "category": "Glutenhaltiges Getreide", "id": "E" },
      { "title": "Milch", "category": "Milch und Milchprodukte", "id": "F" },
      { "title": "Laktose", "category": "Milch und Milchprodukte", "id": "G" },
      { "title": "Ei", "category": "Eier", "id": "H" },
      { "title": "Erdnüsse", "category": "Erdnüsse", "id": "I" },
      { "title": "Sojabohnen", "category": "Sojabohnen", "id": "J" },
      { "title": "Mandeln", "category": "Schalenfrüchte", "id": "K" },
      { "title": "Haselnüsse", "category": "Schalenfrüchte", "id": "L" },
      { "title": "Walnüsse", "category": "Schalenfrüchte", "id": "M" },
      { "title": "Cashewnüsse", "category": "Schalenfrüchte", "id": "N" },
      { "title": "Fisch", "category": "Fisch", "id": "O" },
      { "title": "Krabben", "category": "Krebstiere", "id": "P" },
      { "title": "Garnelen", "category": "Krebstiere", "id": "Q" },
      { "title": "Hummer", "category": "Krebstiere", "id": "R" },
      { "title": "Austern", "category": "Weichtiere", "id": "S" },
      { "title": "Muscheln", "category": "Weichtiere", "id": "T" },
      { "title": "Tintenfisch", "category": "Weichtiere", "id": "U" }
    ],
    "zusatzstoffe": [
      { "title": "Sorbinsäure", "category": "Konservierungsstoffe", "id": 1 },
      { "title": "Natriumbenzoat", "category": "Konservierungsstoffe", "id": 2 },
      { "title": "Tartrazin", "category": "Farbstoffe", "id": 3 },
      { "title": "Chinolingelb", "category": "Farbstoffe", "id": 4 },
      { "title": "Ascorbinsäure", "category": "Antioxidationsmittel", "id": 5 },
      { "title": "Tocopherole", "category": "Antioxidationsmittel", "id": 6 },
      { "title": "Mononatriumglutamat", "category": "Geschmacksverstärker", "id": 7 },
      { "title": "Lecithine", "category": "Emulgatoren", "id": 8 },
      { "title": "Mono- und Diglyceride von Speisefettsäuren", "category": "Emulgatoren", "id": 9 },
      { "title": "Xanthan", "category": "Verdickungsmittel", "id": 10 },
      { "title": "Guarkernmehl", "category": "Verdickungsmittel", "id": 11 },
      { "title": "Saccharin", "category": "Süßungsmittel", "id": 12 },
      { "title": "Aspartam", "category": "Süßungsmittel", "id": 13 },
      { "title": "Citronensäure", "category": "Säuerungsmittel", "id": 14 },
      { "title": "Äpfelsäure", "category": "Säuerungsmittel", "id": 15 },
      { "title": "natürliche Aromastoffe", "category": "Aromen", "id": 16 },
      { "title": "künstliche Aromastoffe", "category": "Aromen", "id": 17 }
    ]
  }

  return data ? (
    <div className={`Item`}>
      <div className={`Item-head`}>
        <h3 className={`Item-title`}>{data.title} <sup>{data.zusatzstoffe ? data.zusatzstoffe.sort((a, b) => a < b).join(', ') : ''}</sup></h3>
        {
          !quantity ? null :
          <span className={`Item-subData`}>{quantity}</span>
        }
        {
          !isHappyHour() ? null :
          <span className={`Item-subData`}>{isHappyHour()}</span>
        }
        {
          data.price ? <span className={`Item-price`}>{data.price} €</span> : ''
        }
      </div>
      <p className={`Item-description`}>{data.description}</p>
      {/* <p>{data.ingredients}</p> */}
      {/* <p>{data.quantity}</p> */}
    </div>
  ) : null
}
