import React from 'react'

export default function IconSnacks() {
  return (
    <svg width="50" height="42" viewBox="0 0 25 21" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.676186 15.8381L9.3386 20.1693C10.051 20.5255 10.8736 19.9465 10.7787 19.1557L9.7392 10.4933C9.65405 9.78377 8.87366 9.38997 8.25231 9.74301L0.629391 14.0742C-0.0660056 14.4693 -0.0391821 15.4804 0.676186 15.8381Z" />
      <path d="M23.2115 3.97841C19.0317 0.545404 15.2698 6.98228 14.4338 10.4153C12.3439 15.9939 18.335 18.1395 21.1216 16.423C23.2115 15.1357 27.3914 7.41141 23.2115 3.97841Z" />
      <path d="M7.20601 5.57812C8.18345 4.64147 8.90743 4.41865 10.328 5.41197C11.6293 6.32186 13.233 4.04101 11.8215 2.568C8.76329 -0.623449 5.81274 3.14582 5.55013 4.33928C5.34412 5.27552 6.22856 6.51476 7.20601 5.57812Z" />
    </svg>
  )
}
