import React from 'react'

export default function IconCheck() {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.3278 12.7573L1.1394 0.807373L0.00015414 1.92433L12.1886 13.8742L13.3278 12.7573Z" fill="white"/>
      <path d="M12.1884 0.80743L0 12.7573L1.13925 13.8743L13.3277 1.92439L12.1884 0.80743Z" fill="white"/>
    </svg>
  )
}
