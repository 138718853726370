import React from 'react'
import './FormError.css'

export default function FormError({response, name}) {
  return (
    <>
      {
        response.errors && response.errors[name] ?
        <p className="FormError">{response.errors[name]}</p>
        : null
      }
    </>
  )
}
