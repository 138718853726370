import React from 'react'

export default function PageFavorites() {
  return (
    <div>
      <h2>Shisha</h2>
      <h2>Drinks</h2>
      <h2>Snacks</h2>
    </div>
  )
}
