import {useEffect, useState} from 'react';
import { on, off, trigger } from './CustomEvents';
import axios from 'axios';

// for dev, remove in production
window.trigger = trigger;

export default function useLogin() {
  const [loggedIn, setloggedIn] = useState(false) // make later inital check if valid token exists in storage

  window.login = {
    loggedId: loggedIn,
    setloggedIn: setloggedIn
  }

  const onSubmit = (event) => {
    console.log('#LOGIN SUBMIT #DATA', event.detail);
  
    axios.post('http://localhost:8080/auth/login', {
      email: event.detail.email,
      password: event.detail.password
    })
    .then((response) => {
      console.log(response);
      const data = response.data;
      setloggedIn(true);
      trigger('login:success', data);
    })
    .catch((error) => {
      console.log(error);
      const data = error.response.data;
      setloggedIn(true);
      trigger('login:error', data);
    });
  }

  const onLogout = (event) => {
    console.log('#LOGIN LOGOUT #DATA', event.detail);
    setloggedIn(false);
  }


  useEffect(
    () => {
      // Set up
      on("login:submit", onSubmit)
      on("login:logout", onLogout)

      // Clean up
      return () => {
        off("login:logout", onLogout)
      };
    },
    []
  );
}