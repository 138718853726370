import React from 'react'
import './Footer.css'
import {
  NavLink
} from "react-router-dom";

export default function Tabbar() {

  return (
    <footer className="Footer">
      <ul>
        <li>
          <NavLink className="Footer-link"  activeClassName='is-active' to={'/impressum'} exact={true}>Impressum</NavLink>
        </li>
        <li>
          <NavLink className="Footer-link"  activeClassName='is-active' to={'/datenschutz'} exact={true}>Datenschutz</NavLink>
        </li>
      </ul>
    </footer>
  )
}
