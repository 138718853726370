import React, { useState } from 'react'
import './Content.css'
// import PageStart from '../pages/PageStart/PageStart'
import PageRegisterVerify from '../../pages/PageRegisterVerify/PageRegisterVerify'
import PageRegister from '../../pages/PageRegister/PageRegister'
import PageLogin from '../../pages/PageLogin/PageLogin'
import PageShisha from '../../pages/PageShisha/PageShisha'
import PageDrinks from '../../pages/PageDrinks/PageDrinks'
import PageSnacks from '../../pages/PageSnacks/PageSnacks'
import PageFavorites from '../../pages/PageFavorites/PageFavorites'
import NoMatch from '../../pages/NoMatch/NoMatch'
import PageDrinksGroup from '../../pages/PageDrinks/PageDrinksDetail';
import PageStart from '../../pages/PageStart/PageStart'
import PageImprint from '../../pages/PageImprint/PageImprint'
import PagePrivacy from '../../pages/PagePrivacy/PagePrivacy'
import Footer from "./Footer";

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";


export default function Content() {
  const [data, setdata] = useState(new Array(0).fill())

  return (
    <div className={`Content`}>
      {/* <h1>Content</h1> */}

      <Switch>
        {/* <Route path="/registrieren/:id">
          <PageRegisterVerify />
        </Route>
        <Route path="/registrieren">
          <PageRegister />
        </Route>
        <Route path="/anmelden">
          <PageLogin />
        </Route> */}

        <Route path="/impressum">
          <PageImprint />
        </Route>

        <Route path="/datenschutz">
          <PagePrivacy />
        </Route>

        <Route path="/drinks/:group">
          <PageDrinksGroup />
        </Route>
        <Route path="/drinks">
          <PageDrinks />
        </Route>

        <Route path="/food">
          <PageSnacks />
        </Route>
        <Route path="/favoriten">
          <PageFavorites />
        </Route>

        <Route exact path="/infos">
          <PageStart />
        </Route>

        <Route path="/">
          <PageShisha />
        </Route>

        <Route path="*">
          <NoMatch />
        </Route>
      </Switch>

      <Footer />


      {data.map((item, index) => (
        <h2 key={`scroll-dummy-${index}`}>Scroll {index}</h2>
      ))}
    </div>
  )
}
