import axios from 'axios'


const initialState = {
  scope: 'login',
  email: '123',
  password: '123',
  response: undefined,
  status: undefined,
  errors: undefined,
  pending: false,
  dispatch: (event) => {
    event.scope = 'login'
    window.store.dispatch(event)
  }
}


const login = function(state) {
  console.log('send login', state);
  window.stores.login.dispatch({
    type: 'set:pending',
    data: true
  });
}

export default function loginReducer(state = initialState, action) {


    switch (`${action.scope}/${action.type}`) {
  
      case `${state.scope}/set:email`:
        state.email = action.data;
        return state
  
      case `${state.scope}/set:password`:
        state.password = action.data;
        return state
  
      case `${state.scope}/send`:
        login(state)
        return state
  
      case `${state.scope}/set:pending`:
        state.pending = action.data;
        return state
  
      default:
        return state
    }
}