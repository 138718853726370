import React, { useState, useRef, useEffect } from 'react'
import Button from '../UI/Button';
import FormError from '../UI/FormError/FormError';
import FormMessage from '../UI/FormMessage/FormMessage';
import Sheet from '../base/Sheet';
import { trigger, on, off } from "../../utils/CustomEvents";

export default function Login() {
  
  const [email, setemail] = useState(null);
  const [password, setpassword] = useState(null);
  const [pending, setpending] = useState(false);
  const [openSheetOnboarding, setopenSheetOnboarding] = useState(false)
  const [response, setresponse] = useState({ errors: {} })

  const [inputFocused, setinputFocused] = useState(false)

  useEffect(() => {
    const onSuccess = (event) => {
      console.log('#LOGIN RESPONSE #DATA', event.detail);
      setpending(false);
      setresponse(event.detail);
      setopenSheetOnboarding(false);
    }
    on("user:login/success", onSuccess)

    const onError = (event) => {
      console.log('#LOGIN ERROR #DATA', event.detail);
      setpending(false);
      setresponse(event.detail);
    }
    on("user:login/error", onError)

    return () => {
      off("user:login/success", onSuccess)
      off("user:login/error", onError)
    }
  }, [on, setpending, setresponse])


  return (
    <>
      <Sheet open={openSheetOnboarding} setopen={setopenSheetOnboarding} name="login" title="Anmelden" minimal={inputFocused} footer={(
        <>
          <Button.Primary onClick={() => {
            setpending(true);
            trigger("user:login", {
              email: email,
              password, password
            });
          }}
          disabled={pending}>Einloggen {inputFocused ? 'true' : 'false'}</Button.Primary>
          <div className="ButtonRow">
            <Button.Link onClick={() => {
              trigger('reset-password:open')
              setopenSheetOnboarding(false);
            }}>Passwort vergessen?</Button.Link>
            <Button.Link onClick={() => {
              trigger('register:open')
            }}>Registrieren</Button.Link>
          </div>
        </>
      )}>
        <form name="FormLogin" style={{
          padding: '12px'
        }}>
          {/* <FormMessage response={response} /> */}
          {visualViewport.height + visualViewport.offsetTop}px

          <div className={`FormField${ email && email != '' ? ' FormField-filled' : '' }${response.errors && response.errors['email'] ? ' FormField-error' : ''}`}>
            <input
              onInput={(e) => {
                setemail(e.target.value)
              }}
              onFocus={() => {setinputFocused(true)}}
              onBlur={() => {setinputFocused(false)}}
              className={`FormField-input`} type="email" id="email" name="email" />
            <label className={`FormField-label`} htmlFor="email">E-Mail-Adresse</label>
          </div>
          <FormError response={response} name="email"/>

          <div className={`FormField${ password && password != '' ? ' FormField-filled' : '' }${response.errors && response.errors['password'] ? ' FormField-error' : ''}`}>
            <input onInput={(e) => {
              setpassword(e.target.value)
            }} className={`FormField-input`} type="password" id="password" name="password" />
            <label className={`FormField-label`} htmlFor="password">Passwort</label>
          </div>
          <FormError response={response} name="password"/>

          {
            !response.message ? null :
            <p className="FormError">{response.message}</p>
          }

        </form>
      </Sheet>
    </>
  )
}
