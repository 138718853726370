import React, { useState, useEffect } from "react";
import Header from "./components/base/Header";
import Tabbar from "./components/base/Tabbar";
import Content from "./components/base/Content";
import Menu from "./components/base/Menu";
import Sheet from "./components/base/Sheet";

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import IconScan from "./icons/IconScan";
import Button from "./components/UI/Button";
import Login from "./components/Auth/Login";
import Register from "./components/Auth/Register";
import useLogin from "./utils/useLogin";
import useUser from "./utils/useUser";

function App() {
  useLogin();
  useUser();

  window.sheets = {};

  const [openSheet, setopenSheet] = useState(false)

  const [showNavigation, setshowNavigation] = useState(true)
  const [lastScrollY, setlastScrollY] = useState(0)
  
  const getEnv = () => {
    return {
      top: getComputedStyle(document.documentElement).getPropertyValue("--sat"),
      bottom: getComputedStyle(document.documentElement).getPropertyValue("--sab"),
      left: getComputedStyle(document.documentElement).getPropertyValue("--sal"),
      right: getComputedStyle(document.documentElement).getPropertyValue("--sar"),
    }
  }
  const [env, setenv] = useState(getEnv())
  

  useEffect(() => {
    const handleTouchMove = (e) => {
      // if (
      //   document.getElementsByTagName( 'html' )[0].classList.contains('Sheet-open')
      // ) {
      //   e.preventDefault();
      // }
    }
    const handleScroll = () => {
      let scrollY = window.scrollY;

      setenv(getEnv());
  
      if (scrollY - lastScrollY > 0 && scrollY > 0) {
        setshowNavigation(false);
      } else {
        setshowNavigation(true);
      }
      setlastScrollY(scrollY);
    }

    // handle scroll for show/hide navigation and other ui
    window.addEventListener('scroll', handleScroll);
    document.body.addEventListener('touchmove', handleTouchMove, { passive: false });
    return () => {
      window.removeEventListener("scroll", handleScroll);
      document.body.removeEventListener('touchmove', handleTouchMove);
    };
  }, [setlastScrollY, lastScrollY, setshowNavigation])

  const onOpenSheet = () => {
    console.log('openSheet', openSheet);
    setopenSheet(!openSheet);
  }

  return (
    <Router>
      {/* <Header slotLeft={(
        <Button.Icon onClick={onOpenSheet}>
          <IconScan />
        </Button.Icon>
      )} /> */}
      <Header />
      <Content />
      <Tabbar hide={!showNavigation} env={env}/>

      {/* <Menu /> */}


      {/* <Sheet open={openSheet} setopen={setopenSheet} title="Tisch auswählen">
        <Switch>
          <Route path="/shishas">
            <img src="https://picsum.photos/1000/600"></img>
            <div className="Sheet-contentWrap">
              <p><strong>Inhaltsstoffe:</strong> Wasser, Zucker, Birnensaft aus Birnensaft- konzentrat (4%), Blaubeersaft aus Blaubeersaftkonzentrat (1%), Honig, natürliches Aroma, Weißtee-Extrakt (0,06%), Antioxidationsmittel: Ascorbinsäure; Säuerungsmittel: Citronensäure</p>       
            </div>
          </Route>
          <Route path="/favoriten">
            {new Array(10).fill().map((item, index) => (
              <h2 key={`scroll-dummy-${index}`}>Scroll {index}</h2>
            ))}
          </Route>
        </Switch>
      </Sheet>


      <Sheet open={openSheetOnboarding} setopen={setopenSheetOnboarding} title="Anmelden" footer={(
        <Button.Primary onClick={()=>{window.FormLogin.submit()}}>Einloggen</Button.Primary>
      )}>
        <Login callback={(result)=>{
          if (!result.error) {
            setopenSheetOnboarding(false);
          }
        }} />
      </Sheet> */}


      <Register />
      <Login />

      
    </Router>
  );
}

export default App;
