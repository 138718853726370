import React, { useEffect, useState } from 'react'
import Item from '../../components/Item/Item'
import Additives from '../../components/Additives'

import { useParams } from 'react-router-dom';

export default function PageDrinksDetail() {
  let { group } = useParams();
  const [data, setdata] = useState([]);
  
  const getData = ()=>{
    fetch(`/data/drinks/${group}.json?v=1.0.7`, {
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }).then(function(response){
      return response.json();
    }).then(function(json) {
      setdata(json);
    });
  }
  
  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {data.map((item, index) => (
        <>
          {
            item.spacer ?
            <div class="spacer" /> :
            item.heading ? 
            <h2 class="px">{item.title}</h2> : 
            <Item key={index} data={item} />
          }
        </>
      ))}
      <Additives />
    </>
  )
}
