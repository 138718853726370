import React, { useState, useRef } from 'react'
import axios from 'axios';
import Button from '../UI/Button';
import ExternalLink from '../UI/ExternalLink/ExternalLink';
import FormError from '../UI/FormError/FormError';
import FormMessage from '../UI/FormMessage/FormMessage';
import Sheet from '../base/Sheet';
import IconCheck from '../../icons/IconCheck';

export default function Register() {

  const [openSheetRegister, setopenSheetRegister] = useState(false)
  const [inputValue, setinputValue] = useState(null);
  const [inputValue2, setinputValue2] = useState(false);
  const [registerSuccessful, setregisterSuccessful] = useState(false)


  const initialResonse = {
    errors: {}
  }

  const [response, setresponse] = useState(initialResonse)
  const refForm = useRef()

  window.FormRegister = {
    current: refForm
  }
  window.FormRegister.submit = () => {

    // console.log(event.target["email"]);
    axios.post('http://localhost:8080/auth/register', {
      email: inputValue,
      terms_of_service: inputValue2
    })
    .then(function (response) {
      const data = response.data;
      setresponse(data);
      console.log(response);

      setregisterSuccessful(true);
    })
    .catch(function (error) {
      const data = error.response.data;
      setresponse(data);
      console.log('#Error', error);
      console.log(data.message);

      setregisterSuccessful(false)
    });
  }

  return (
    <>
      <Sheet open={openSheetRegister} setopen={setopenSheetRegister} name="register" title="Registrieren" footer={(
        <Button.Primary onClick={() => {
          window.FormRegister.submit()
        }}>
          {
            !registerSuccessful ? 'Bestätigungs-Email abschicken' : 'Daten bestätigen'

          }
        </Button.Primary>
      )}>
        
        <form ref={refForm} style={{
        padding: '12px 12px 24px 12px'
      }}>

        <FormMessage response={response} />

        <div className={`FormField${ inputValue && inputValue != '' ? ' FormField-filled' : '' }${response.errors && response.errors['email'] ? ' FormField-error' : ''}`}>
          <input onInput={(e) => {setinputValue(e.target.value)}} className={`FormField-input`} type="email" id="email" name="email" />
          <label className={`FormField-label`} htmlFor="email">E-Mail-Adresse</label>
        </div>
        <FormError response={response} name="email"/>

        <div className={`FormFieldCheckbox${response.errors && response.errors['terms_of_service'] ? ' FormField-error' : ''}`}>
          <div className={`FormFieldCheckbox-checkbox`} onClick={()=>{
            console.log('###### click checkbox');
            setinputValue2(!inputValue2)
            }}>
            {/* <input className={`FormFieldCheckbox-input`} onChange={(e) => {setinputValue2(e.target.checked)}} type="checkbox" id="terms_of_service" name="terms_of_service" /> */}
            {
              !inputValue2 ? null : 
              <IconCheck />
            }
          </div>
          <label className={`FormFieldCheckbox-label`} htmlFor="terms_of_service">
            Hiermit erklärst Du Dich mit unseren <ExternalLink to="https://keyshift.co/policies/legal">AGB</ExternalLink> und unserer <ExternalLink to="https://keyshift.co/policies/privacy">Datenschutzerklärung</ExternalLink> einverstanden.
          </label>
        </div>
        <FormError response={response} name="terms_of_service"/>

      </form>


      </Sheet>
    </>
  )
}
