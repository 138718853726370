import React from 'react'

export default function PageStart() {
  return (
    <div className="px">
      {/* <h1>Impressum</h1> */}

      <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
      <p>Oguzhan G&uuml;zel<br />
      Long Island Shisha Cocktailbar<br />
      Schaufelderstra&szlig;e 34<br />
      30167 Hannover</p>

      {/* <p>Handelsregister: *Registernummer1234567890*<br /> */}
      {/* Registergericht: *RegistergerichtABCD*</p> */}

      <h2>Kontakt</h2>
      <p>Telefon: 015256961050<br />
      E-Mail: oguzhan-g@web.de</p>

      {/* <h2>Umsatzsteuer-ID</h2>
      <p>Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a Umsatzsteuergesetz:<br />
      *Umsatzsteuer-ID1234567890*</p> */}

      <h2>Angaben zur Berufs&shy;haftpflicht&shy;versicherung</h2>
      <p><strong>Name und Sitz des Versicherers:</strong><br />
      VGH Hannover<br />
      Schiffgraben 4<br />
      30159 Hannover</p>
      <p><strong>Geltungsraum der Versicherung:</strong><br />Deutschland</p>

      <h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
      <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>

      <p>Quelle: <a href="https://www.e-recht24.de">eRecht24</a></p>
    </div>
  )
}
