import React from 'react'
import BonusCard from '../../components/BonusCard/BonusCard'
import CardSlider from '../../components/CardSlider/CardSlider'

export default function PageStart() {
  return (
    <>
      {/* <BonusCard /> */}
      {/* <CardSlider /> */}

      {/* <img src="https://picsum.photos/1000/600" width="100%" alt="Dummy content" /> */}

      <div class="px">
        <h2>Öffnungszeiten</h2>
        <p>Montag - Donnerstag: 15:00 - 00:00 Uhr</p>
        <p>Freitag - Samstag: 15:00 - 02:00 Uhr</p>
        <p>Sonntag: 15:00 - 00:00 Uhr</p>

        {/* <h2>Happy Hour</h2>
        <p>Täglich von 14:00 bis 16:00 Uhr.</p> */}
      </div>

    </>
  )
}
